.section {
    width: 75vw;
    padding-top: 1px;
    text-align: center;
}

.section h2 {
    font-size: 20px;
    text-decoration: underline;
    margin-bottom: 1rem;
}

.section p {
    margin-top: auto;
    font-size: 16px;
    font-weight: 100;
    text-align: justify;
    line-height: 1.6;
}
.section h3 {
    margin: auto;
}
/* Styl dla elementów listy terapii */
.therapy-list {
    list-style-type: none; /* Usunięcie domyślnych punktorów */
    padding-left: 20vw;
    text-align: center; /* Wyśrodkowanie całej listy */
}

/* Styl dla elementów listy terapii */
.therapy-list li {
    text-align: left; /* Wyrównanie tekstu do lewej strony */
    margin-bottom: 10px; /* Odstęp między elementami listy */
    display: flex; /* Użycie flexbox dla łatwiejszego zarządzania wyrównaniem */
    align-items: center; /* Wyśrodkowanie w pionie */
    font-size: medium;
    font-weight: 100;
}

/* Styl dla punktorów listy terapii */
.therapy-list li::before {
    content: '▪'; /* Zastosowanie punktora */
    margin-right: 10px; /* Odstęp między punktorem a tekstem */
    flex: none; /* Zapobiega rozciąganiu punktora */
    align-self: flex-start; /* Wyśrodkowanie punktora względem górnego marginesu elementu listy */
}


.section.office-image img {

    border-radius: 10px; /* Przykładowy styl, np. zaokrąglone rogi */
    margin-top: -50px;
}