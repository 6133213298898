/* src/components/Header.css */
.App-header {
    padding: 1px;
    margin: 1rem 1rem 1rem 1rem;
    background-color: #646b52;
    color: white;
    border-radius: 10px;
    box-shadow: -1px 1px 30px 6px #90977e;
    line-height: 1.5rem;
  }
  
  .App-header h1 {
    font-size: 32px;
  }