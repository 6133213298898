/* Contact.css */

.contact-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 15px;
}

.contact-container h2 {
    font-size: 26px;
    margin-bottom: 10px;
    color: #000000;
}

.contact-container p {
    font-size: 18px;
    margin: 5px 0;
    color: #000000;
}

.contact-address {
    font-weight: 100;
}

.contact-postcode {
    font-weight: 100;
}

.contact-phone a {
    color: #007BFF;
    text-decoration: none;
}

.contact-phone a:hover {
    text-decoration: underline;
}

.contact-email a {
    color: #007BFF;
    text-decoration: none;
}

.contact-email a:hover {
    text-decoration: underline;
}
