/* src/components/ImageContainer.css */
.header-image-container {
    margin: 1rem auto;
    text-align: center;
}

.header-image {
    max-width: 100%;
    width: 200px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header-image-caption {
    margin-top: 0.5rem;
    font-size: 1.5rem;
    color: #000000;
    font-weight: bold;
}
