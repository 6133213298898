/* Nav.css */

.Nav {
    position: fixed;
    cursor: pointer;
    background-color: #EFF2E8;
    box-shadow: -1px 1px 30px 6px #90977e;
    z-index: 1000;
    padding: 1rem;
    transition: padding 0.3s, box-shadow 0.3s;
    justify-content: center;
    height: auto;
    width: 100%;
    top: 0;
    
}

.menu-icon {
    display: none; /* Ukryj ikonę menu na większych ekranach */
    cursor: pointer;
}

.menu-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
}

.menu-links a {
    padding: 10px 15px;
    font-size: large;
    text-decoration: none;
    color: #646b52; /* Domyślny kolor linków */
}