.Buttons {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1000;
    transition: opacity 0.3s ease;
}

.Buttons-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Buttons-buttons button {
    background-color: #646b52;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative; /* Potrzebne dla pseudo-elementów */
    overflow: hidden; /* Ukrywa elementy wykraczające poza przycisk */
    width: 50px; /* Początkowa szerokość przycisku */
    transition: width 0.3s ease; /* Animacja zmiany szerokości */
}

.Buttons-buttons button .button-icon {
    width: 30px; /* Dostosuj szerokość ikon */
    height: 30px; /* Dostosuj wysokość ikon */
}

.Buttons-buttons button:hover {
    background-color: #3d4231;
    width: 150px; /* Zwiększa szerokość przycisku na hover */
}

.Buttons-buttons button::after {
    content: attr(txt); /* Pobiera tekst z atrybutu txt */
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%); /* Wyśrodkowuje napis w pionie */
    opacity: 0;
    transition: opacity 0.3s ease;
    white-space: nowrap; /* Zapobiega zawijaniu tekstu */
    width: 50%;
}


.Buttons-buttons button:hover::after {
    opacity: 1;
    font-weight: bold;
}

/* Styl tylko dla aktywnego przycisku */
.Buttons-buttons button:hover:not(:hover)::after {
    opacity: 0;
}

