.App {
  text-align: center;
  display: block;
  padding-top:70px;
}


.header-image-container {
  margin: 1rem auto;
  text-align: center;
  
}

.header-image {
  max-width: 100%;
  width: 250px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header-image-caption {
  margin-top: 0.5rem;
  font-size: 1.5rem;
  color: #000000;
  font-weight: bold;
}

.main-content {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.office-image-container {
  padding-top: 100px;
  align-items: center;
}

.address-container  {
  font-weight: bold;
  color: #333;
  font-size: 18px;
  line-height: 0.5
}

@media (max-width: 768px) {
  .App {
    padding-top:50px;
    padding-bottom: 50px;
  }

  .main-content {
    padding: 1rem;
  }

  .section {
    padding: 1rem;
    font-size: 1rem;
    width: 90vw;
  }

  .Nav {
    flex-direction: column;
    padding: 0px;
}
  .menu-icon {
    display: block;
    padding: 10px;
}

  .menu-links {
    position: absolute;
    top: calc(100%); /* Umieść menu dokładnie pod Nav'em */
    background-color: #f9f9f9;
    flex-direction: column;
    width: 100vw;
    gap: 0;
    padding: 0px;
    display: none; /* Domyślnie ukryj menu na mniejszych ekranach */
}

  .menu-links.active {
    display: flex; /* Pokaż menu po kliknięciu na ikonę hamburgerową */
    left: 0px;
    right: 0px;
}

  .menu-links a {
    margin: 0px 0;
    color: #646b52; /* Zmieniony kolor linków */
    font-family: 'Arial', sans-serif; /* Zmieniony font */
    font-size: 16px; /* Zmieniony rozmiar fontu */
}
  .Nav-links {
    display: flex;
    gap: 1rem;
  }

  .Nav-links a {
    text-decoration: none;
    font-size: 1rem;
}
  .App-header h1 {
    font-size: 26px;
    line-height: 1.2;
  }


  .therapy-list {
    list-style-type: none; /* Usunięcie domyślnych punktorów */
    text-align: center; /* Wyśrodkowanie całej listy */
    padding-left: 20vw;
  }
  .therapy-list li {
    text-align: left; /* Wyrównanie tekstu do lewej strony */
    margin-bottom: 10px; /* Odstęp między elementami listy */
    display: flex; /* Użycie flexbox dla łatwiejszego zarządzania wyrównaniem */
    align-items: center; /* Wyśrodkowanie w pionie */
  }

  /* Styl dla punktorów listy terapii */
  .therapy-list li::before {
    content: '▪'; /* Zastosowanie punktora */
    margin-right: 10px; /* Odstęp między punktorem a tekstem */
    flex: none; /* Zapobiega rozciąganiu punktora */
    align-self: flex-start; /* Wyśrodkowanie punktora względem górnego marginesu elementu listy */
  }

  .office-image-container img {
      max-width: 90%;
    }
  .Buttons {
      bottom: 0; /* Ustawienie na samym dole strony */
      left: 0; /* Ustawienie na całej szerokości */
      width: 100%; /* Pełna szerokość */
      display: flex;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.5); /* Opcjonalne tło dla widoczności */
      padding: 10px 0; /* Opcjonalne padding dla lepszej widoczności */
  }

  .Buttons-buttons {
      flex-direction: row; /* Układ w rzędzie */
      gap: 3.5rem;
  }

  .Buttons-buttons button {
      flex: 1; /* Przyciski zajmują równą szerokość */
      border-radius: 10px; /* Opcjonalne zaokrąglenie */
      width: auto !important;
  }

  .Buttons-buttons button .button-icon {
      width: 20px; /* Dostosuj szerokość ikon */
      height: 20px; /* Dostosuj wysokość ikon */
  }
  
  .Buttons-buttons button::after {
    display: none; /* Ukrywa napis */
  }
}